import React, { Component } from 'react';
import {language} from "../assets/languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      text: '',
      requestActive: false,
      emailSent: false,
      emailErrors: [],
      textErrors: [],
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let Merrors = [];
    if (this.state.email.length < 1) {
      Merrors.push(language[this.props.match.params.language].contact.emailErr);
      this.setState({
        emailErrors: Merrors,
      });
      return;
    }

    if (this.state.text.length < 10) {
      Merrors.push(language[this.props.match.params.language].contact.textErr);
      this.setState({
        textErrors: Merrors,
        emailErrors: [],
      });
      return;
    }

    this.setState({
      requestActive: true,
      emailErrors: [],
      textErrors: [],
    });
    const Http = new XMLHttpRequest();
    const url='https://ldapps.cz/handlecontactform';

    Http.open("POST", url);
    Http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    Http.send(JSON.stringify({
      email: this.state.email.toLowerCase(),
      text: this.state.text,
      to: 'info@htdm.cz',
      case: 'HTDM POPTAVKA',
    }));
    Http.onreadystatechange=(e)=> {
      if (Http.readyState==4 && Http.status==200) {
        this.setState({
          requestActive: false,
          email: '',
          text: '',
          emailSent: true,
        });

        this.props.contextValue.onAddNotification({
          title: language[this.props.match.params.language].contact.notificationSuccessTitle,
          level: 'info',
          autoDismiss: 5,
          children: (
            <div>
              {language[this.props.match.params.language].contact.notificationSuccessMessage}
            </div>
          ),
        });
      }
      if (Http.readyState === 4 && Http.status !== 200) {
        this.setState({
          requestActive: false,
          emailSent: false,
        });

        this.props.contextValue.onAddNotification({
          title: 'Email error',
          level: 'error',
          autoDismiss: 5,
          children: (
            <div>
              {language[this.props.match.params.language].contact.notificationErrorMessage}
            </div>
          ),
        });
      }
    }
  }

  render() {
    let { email, text, emailSent, emailErrors, textErrors } = this.state;
    const lan = this.props.match.params.language;
    return (
      <div className="contactForm">
        <div className="heading" style={{ margin: "0px 0px 20px" }}>
          <h3><span style={{color: 'white'}}>{ language[lan].contact.header }</span></h3>
        </div>
        <div className='row'>
          <div className='col-lg-2 fastContact'>
            <h5 style={{fontWeight: 'bold'}}>
              Ing. Daniel Lazar
            </h5>
            <div>
              Tel: +420 725 555 915
            </div>
            <div>
              Email: info@htdm.cz
            </div>
          </div>
          <div className='col-lg-8'>
            <form onSubmit={(e) => this.onSubmit(e)}>
              <div className="form-group text-left">
                <label style={{color: 'white'}} htmlFor="email">{ language[lan].contact.yourEmail }</label>
                <input className="form-control item"
                       type="email"
                       id="email"
                       value={email}
                       aria-describedby="emailHelpBlock"
                       onChange={(e) => this.setState({email: e.target.value})}/>
                <small id="emailHelpBlock" className="form-text text-muted">
                  {
                    Object.keys(emailErrors).map((key) => {
                      return <div style={{color: '#ff2039', fontSize: '15px', fontWeight: 'bold'}} key={key}>{emailErrors[key]}</div>
                    })
                  }
                </small>
              </div>
              <div className="form-group text-left">
                <label style={{color: 'white'}} htmlFor="message">{ language[lan].contact.message }</label>
                <textarea className="form-control item"
                          value={text}
                          aria-describedby="textHelpBlock"
                          onChange={(e) => this.setState({text: e.target.value})}
                          id="message" style={{ minHeight: '200px' }}/>
                <small id="textHelpBlock" className="form-text text-muted">
                  {
                    Object.keys(textErrors).map((key) => {
                      return <div style={{color: '#ff2039', fontSize: '15px', fontWeight: 'bold'}} key={key}>{textErrors[key]}</div>
                    })
                  }
                </small>
              </div>
              <div className="form-group">
                {
                  this.state.requestActive ? <div className="loader"></div> :
                    <button className="btn btn-primary btn-block btn-lg" type="submit">
                      { language[lan].contact.sent }
                    </button>
                }
              </div>
            </form>
          </div>
          <div className='col-lg-2'>

          </div>
          <div className='col-lg-12'>
            <div className="footer-copyright text-center py-3 coypright">
              2020 © <a href="https://htdm.cz"> High-Tech Digital Modules s.r.o.</a>, Inc. All rights reserved
              <span className='socialNetwork'><FontAwesomeIcon icon={['fab', 'facebook']} /></span>
              <span className='socialNetwork'><FontAwesomeIcon icon={['fab', 'twitter']} /></span>
              <span className='socialNetwork'><FontAwesomeIcon icon={['fab', 'youtube']} /></span>
              <span className='socialNetwork'><FontAwesomeIcon icon={['fab', 'slack']} /></span>
            </div>

          </div>
        </div>

      </div>
    );
  }
}


export default Contact;