import React, { Component } from 'react';
import logo from "../logo.png";
import CollapseButton from './CollapseButton'
import cz from '../assets/icons/cz.png';
import en from '../assets/icons/en.png';
import {withGetScreen} from 'react-getscreen'
import { Link } from 'react-router-dom';

const navStates = {
  home: 1,
  company: 2,
  specialization: 3,
  projects: 4,
  contacts: 5,
};

const navStatesStrings = {
  cz: {
    home: 'Úvod',
    company: 'Firma',
    specialization: 'Specializace',
    projects: 'Projekty',
    contacts: 'Kontakty',
  },
  en: {
    home: 'Home',
    company: 'Company',
    specialization: 'Specialization',
    projects: 'Projects',
    contacts: 'Contacts',
  }
};

const languageStatesObjects = {
  cz: {
    icon: cz,
    label: 'Czech',
  },
  en: {
    icon: en,
    label: 'English'
  },
};

class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshRequired: false,
      active: navStates[this.props.match.params.nav],
    };
  }

  setNavState(active) {
    window.scrollTo(0,0);
    this.setState({ active, refreshRequired: true });
  }
  componentDidUpdate() {
    if (this.state.refreshRequired) {
      this.setState({
        refreshRequired: false,
      });
    }
    if (this.state.active !== navStates[this.props.match.params.nav]) {
      this.setState({
        active: navStates[this.props.match.params.nav],
      });
    }
  }

  changeLanguage(language) {
    this.props.history.replace(`/${language}/${this.props.match.params.nav}`);
  }

  renderLanguageSelector(language) {
    const isMobile = this.props.isTablet() || this.props.isMobile();
    if (isMobile) {
      return <div className='text-center'>
        {
          Object.keys(languageStatesObjects).map((key) => {
            return <div key={key} style={{cursor: 'pointer', display: 'inline'}}
                        className="dropdown-item" onClick={() => this.changeLanguage(key)}>
              <img alt="logo" src={languageStatesObjects[key].icon}/> {languageStatesObjects[key].label}
            </div>
          })
        }
      </div>;
    } else {
      return <div className="dropdown">
        <div style={{cursor: 'pointer'}} className='dropdown-toggle' id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false">
          <img alt="logo" src={languageStatesObjects[language].icon}/>
        </div>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          {
            Object.keys(languageStatesObjects).map((key) => {
              return <div key={key} style={{cursor: 'pointer'}}
                          className="dropdown-item" onClick={() => this.changeLanguage(key)}>
                <img alt="logo" src={languageStatesObjects[key].icon}/> {languageStatesObjects[key].label}
              </div>
            })
          }
        </div>
      </div>
    }
  }

  render() {
    if (this.state.refreshRequired) {
      return null;
    }
    const language = this.props.match.params.language;

    return <div className='sticky-top'>
      <nav className="navbar navbar-expand-md navbar-default">

        <a className="navbar-brand" href="/">
          <img src={logo} className="App-logo" alt="logo"/>
        </a>

        <CollapseButton dataTarget='.dual-collapse2'/>
      <div className="navbar-collapse collapse w-100 order-3 order-md-0 dual-collapse2"/>
      <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
        <ul className="navbar-nav ml-auto">
          {
            Object.keys(navStatesStrings.cz).map((key) => {
              return <li key={key} className={'nav-item' + (this.state.active === navStates[key] ? ' active' : '')}
                         onClick={() => this.setNavState(navStates[key])}>
                <Link className="nav-link" to={`/${language}/${key}`}>{navStatesStrings[language][key]}</Link>
              </li>
            })
          }

        </ul>
      </div>
      <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
        <div className="navbar-nav ml-auto">
          {
            this.renderLanguageSelector(language)
          }
        </div>
      </div>
    </nav></div>
  }
}

export default withGetScreen(Navigation);