import React from 'react';

const language = {
  cz: {
    homeHeaderH3: 'Vývoj aplikací na míru',
    homeHeaderH6: 'Hardware, Firmware, Uživatelské grafické prostředí, IoT, 3D prototypy',
    homeIntro: <p>
      <span className='BoldFont'>
        Jsme mladá začínající firma skládající se z týmu odborníků pro různá odvětví. Specializujeme se především na projekty, které tato odvětví
        propojují, a soustředíme se na moderní technologie.
        Používáme agilní metody vývoje, automatické testování a postupnou integraci softwaru.
      </span>
      Máme také četné zkušenosti v oblasti návrhu elektroniky a desek plošných spojů nebo
      programování mikroprocesorů. Používáme bezdrátové technologie jako jsou zigbee, bluetooth nebo klasické I2C, UART, SPI atd.
      Tato zařízení integrujeme do nadřazených systémů s databázemi a uživatelským rozhraním např. v prohlížeči nebo v mobilním zařízení.
    </p>,
    skillBox: {
      employees: <h3 className="name">Nyní <span className='redFont'>5 </span>zaměstnanců</h3>,
      experience: <h3 className="name">Více než <span className='redFont'>3 roky </span>zkušeností</h3>,
      agile: <h3 className="name"><span className='redFont'>Agilní </span>vývoj</h3>,
      hotTeam: <h3 className="name"><span className='redFont'>Mladý zapálený </span>kolektiv</h3>,
      laserGame: <h3 className="name">Vlastní <span className='redFont'>Laser game</span> projekt</h3>,
    },
    homeSpecHeader: 'Specializace',
    contact:{
      header: 'Kontaktní formulář',
      header2: 'Kontakty',
      yourEmail: 'Váš email',
      message: 'Zpráva',
      sent: 'Odeslat',
      emailErr: 'Email musí být vyplněn',
      textErr: 'Text zprávy musí mít alepsoň 10 znaků',
      notificationSuccessTitle: 'Email přijat',
      notificationSuccessMessage: 'Vaše zpráva byla v pořádku doručena, budeme Vás kontaktovat' +
        ' během 2 pracovních dnů',
      notificationErrorMessage: 'Něco se pokazilo, prosím zkuste to znovu',
    },
    projects:{
      main: <div>
        <p className='text-left'>
          Naše firma se specializuje na komplexní aplikace s využitím technologií, zmíněných v našem portfoliu.
          Umíme navrhnout hardware pro měření fyzikální veličiny a její převod na elektrický signál. Následně je získaný signál vyhodnocen pomocí
          MCU a odeslán nadřazeným systémům. Jeho hodnota je uložena do databáze a
          zároveň zobrazena v uživatelské aplikaci. Toto může samozřejmě fungovat také opačným směrem na základě vstupních dat je
          vytvořen akční zásah na koncové zařízení. Kromě těchto komplexních aplikací se zabýváme také projekty, které mohou podle požadavků zákazníka
          zahrnovat pouze hardware, firmware nebo uživatelskou aplikaci. Specializaci lze shrnout v následujících bodech:
        </p>
        <div className='projects text-left'>
          <ul>
            <li> Vývoj a výroba HW </li>
            <li> Vývoj a výroba 3D prototypů </li>
            <li> Měření a sběr dat z různých senzorů </li>
            <li> Řízení koncových zařízení </li>
            <li> Vizualizace dat </li>
            <li> Aplikace pro nastavení a řízení celého systému v reálném čase </li>
          </ul>
        </div>
      </div>,
      p1: {
        title: 'Vývoj hardware',
        description: 'Vývoj hardware pro měření nebo řízení koncového zařízení.',
        fullDesc: <div>
          <ul>
            <li> Návrh elektronického zapojení </li>
            <li> Návrh desky plošného spoje </li>
            <li> Výroba prototypu </li>
            <li> Testování a ladění </li>
          </ul>
          <h5><span className='redFont'>Zkušenosti</span></h5>
          <ul>
            <li> Elektronika pro nabíjení baterií </li>
            <li> MCU vstupy a výstupy, komunikace a periférie. </li>
            <li> Výkonová elektronika </li>
            <li> Zpracování a úprava signálů </li>
          </ul>
        </div>,
      },
      p2: {
        title: 'Vývoj firmware',
        description: 'Oživení hardware, vývoj kompletního firmwaru.',
        fullDesc: <div>
          <ul>
            <li> Vývoj low level kódu převážně v jazyce C nebo assembler </li>
            <li> Testování a optimalizace kódu </li>
          </ul>
          <h5><span className='redFont'>Zkušenosti</span></h5>
          <ul>
            <li> Vyčítání a ovládání digitálních nebo analogových vstupů a výstupů </li>
            <li> Řízení servo motorů nebo DC motorů </li>
            <li> PID regulace na MCU </li>
            <li> Komunikace I2C, SPI, UART, CAN </li>
            <li> Práce s úložištěm Flash, SD karty, atd. </li>
          </ul>
        </div>
      },
      p3: {
        title: 'Vývoj software a nadřazených systémů, IoT',
        description: 'Uživatelské grafické rozhraní pro vizualizaci dat a řízení v reálném čase.',
        fullDesc: <div>
          <ul>
            <li> Databáze pro aplikaci </li>
            <li> Vývoj kódu pro <i>backend</i>, C++, Nodejs, Python, REST api, websocket </li>
            <li> Vývoj <i>frontend</i> aplikace, React, javascript, HTML, CSS, Android - JAVA </li>
            <li> Integrace více různých zařízení do jednoho komplexního systému </li>
          </ul>
          <h5><span className='redFont'>Zkušenosti</span></h5>
          <ul>
            <li> Databáze Mysql, Postgresql </li>
            <li> Komunikace HTTP/2 (REST, SOAP) nebo websockety </li>
            <li> Linux pro hostování aplikace, Bash scripty pro údržbu </li>
            <li> C++ server aplikace pro linux</li>
            <li> Nodejs pro tvorbu REST api a interakci s databází</li>
            <li> Tvorba kompletních <i>frontend</i> aplikací pro webové prohlížeče nebo mobilní zařízení</li>
          </ul>
        </div>
      },
      p4: {
        title: 'Vývoj 3D modelů a jejich prototypů',
        description: 'Vývoj 3D modelu a výroba jeho prototypu pomocí 3D tiskárny.',
        fullDesc: <div>
          <ul>
            <li> Vývoj 3D modelu </li>
            <li> Tisk prototypu na 3D tiskárně </li>
            <li> Kompletace prototypu </li>
          </ul>
          <h5><span className='redFont'>Zkušenosti</span></h5>
          <ul>
            <li> Vývoj 3D modelu pro Zbraň lasergame </li>
            <li> Vývoj krytů pro zhotovenou elektroniku </li>
            <li> Zkušenosti s obráběním</li>
          </ul>
        </div>
      }
    },
    company: {
      header: 'Firma',
      founders: 'Zakladatelé',
      description: <div>
        <p>
          Firma
          <span className='redFont'> H</span>i-
          <span className='redFont'>T</span>ech
          <span className='redFont'> D</span>igital
          <span className='redFont'> M</span>odules oficiálně vznikla v červenci 2020. Neoficiálně
          ale funguje od roku 2015 kdy se parta čtyř lidí rozhodla vytvořit vlastní lasergame vybavení.
          Během čtyř let se tento projekt dostal do fáze funkčních prototypů a
          na podzim 2019 byl uveden do provozu. Firma při jeho realizaci získala spoustu zkušeností a
          dovedností v oblasti vývoje elektroniky, desek plošných spojů, programovaní MCU, komunikací a
          tvorby uživatelského prostředí. Pro dosažení cíle pilotního projektu byl využit agilní
          způsob vývoje, plánování, verzování, automatické testování a postupné integrace všech prvků do finální
          aplikace. Zmíněné zkušenosti nyní chceme v rámci firmy využít a zdokonalit, a proto <span className='redFont'>potřebujeme najít nové výzvy, nové projekty.</span></p>
        <p>
          Absolutní jádro firmy tvoří čtyři lidé, z nichž se každý specializuje na jiné odvětví vývoje aplikací. Členové firmy se tak vzájemně doplňují, a každý z nich posouvá projekt na vyšší úroveň,
          díky tomu lze dosahovat stanovených cílů projektu.
        </p>
      </div>,
      foundersDetails: <div>
        <h4><span className='redFont'>H</span>onza Sopuch</h4>
        <p>Vlastník zábavního centra <a target="_blank" rel="noopener noreferrer" href='https://www.zabavahornik.cz'>Horník</a> v Ostravě, který podporuje pilotní projekt lasergame. </p>
        <h4><span className='redFont'>T</span>omáš Dočekal</h4>
        <p>Specialista na programovaní jednočipů, vývoj HW a vývoj 3D modelů. </p>
        <h4><span className='redFont'>D</span>aniel Lazar</h4>
        <p>Projektové řízení, nadřazené systémy, linux a vývoj software. </p>
        <h4><span className='redFont'>M</span>atěj Golembiovský</h4>
        <p>Specialista na vývoj elektroniky a desek plošných spojů.</p>
      </div>,
    },
    projectsDone: {
      header: 'Realizované projekty',
      introduction: <>
        <h3>Independent lasergame</h3>
        <p className='text-left'>Pilotní projekt společnosti HTDM. Projekt vytvořený tzv. na zelené louce.
          Vlastní elektronika, jedinečný design, vše snadno nastavitelné a přehledné v grafickém prostředí na webu.
          <a target="_blank" href='https://www.mamnapad.cz/ostravska-inovacni-spolecnost-htdm-a-jejich-nejmodernejsi-lasergame/'> Článek</a>
        </p>

        <ul className='text-left'>
          <li>Využity technologie jako ZigBee</li>
          <li>Vlastní elektronika</li>
          <li>3D modely pro zbraň, vestu a kryty elektroniky</li>
          <li>STM, ATmega mikroprocesory a jejich programování </li>
          <li>Jako hlavní řídící jednotka využito <i>Raspberry pi</i> pro koordinaci zigbee sítě a hostování aplikace </li>
          <li>Uživatelsky přívětivá jednoduchá aplikace pro vizualizaci a nastavení  </li>
        </ul>
      </>
    },
    roles: {
      jan: "marketing a obchod",
      daniel: "vývoj software",
      matej: "vývoj hardware",
      tomas: "vývoj 3D a firmware",
    }
  },
  en: {
    homeHeaderH3: 'Development of applications',
    homeHeaderH6: 'Hardware, Firmware, User interface, IoT, 3D prototyping',
    homeIntro: <p>
      <span className='BoldFont'>
        Start up, more than 3 years experience.
        Team of specialists for different industries like Hardware, Firmware, UI application, 3d models and it's prototyping.
        We specialize in projects which are connecting these knowledge.
        We use agile methods, continues integration and testing for SW development. We are focused on modern technologies for development and also for project management.
      </span>
      Our other experience are design of PCB huge knowledge about MCU and it's programing.
      Experience with wireless technologies like zigbee, bluetooth or wired I2C, UART, SPI, etc.
      We have knowledge with integration of HW components into higher system with database and User Interface e.g. in browser or mobile device.
    </p>,
    skillBox: {
      employees: <h3 className="name">Currently <span className='redFont'>5 </span>employees</h3>,
      experience: <h3 className="name">More than <span className='redFont'>3 years </span>experience</h3>,
      agile: <h3 className="name"><span className='redFont'>Agile </span>development</h3>,
      hotTeam: <h3 className="name"><span className='redFont'>Young ignited </span>team</h3>,
      laserGame: <h3 className="name">Homegrown <span className='redFont'>Laser game</span> project</h3>,
    },
    homeSpecHeader: 'Specialization',
    contact:{
      header: 'Contact form',
      header2: 'Contacts',
      yourEmail: 'Your email',
      message: 'Message',
      sent: 'Confirm',
      emailErr: 'Email cannot be empty',
      textErr: 'Text must contain at least 10 characters',
      notificationSuccessTitle: 'Email received',
      notificationSuccessMessage: 'Your email has been received we will reply in two working days',
      notificationErrorMessage: 'Something went wrong try it again',
    },
    projects:{
      main: <div>
        <p className='text-left'>
          Our company is focusing on complex application, where are used all parts of our portfolio.
          We know how to design hardware device which is used for converting physical value into electric value.
          This value is later evaluated by MCU and sent to higher level systems, where value is stored to database and
          visualized in user interface. This can work also vice-versa where action for end device is created based on user data inputs.
          Only part of the project can be delivered also it means HW/FW or only user interface. Our specialization can be covered by following bullets:
        </p>
        <div className='projects text-left'>
          <ul>
            <li> Development of HW </li>
            <li> 3D prototyping and manufacture </li>
            <li> Measuring and collecting data from sensors</li>
            <li> Control of end devices </li>
            <li> Data visualization </li>
            <li> User interfaces for real time control </li>
          </ul>
        </div>
      </div>,
      p1: {
        title: 'Hardware development',
        description: 'Hardware development for measuring or control of end device.',
        fullDesc: <div>
          <ul>
            <li> Design of electronic device </li>
            <li> PCB design </li>
            <li> Prototype development </li>
            <li> Testing and tunning </li>
          </ul>
          <h5><span className='redFont'>Experience</span></h5>
          <ul>
            <li> Elektronic for baterry designs </li>
            <li> MCU input and outputs, communication, peripherals. </li>
            <li> High power electronic</li>
            <li> Signal processing</li>
          </ul>
        </div>,
      },
      p2: {
        title: 'Firmware development',
        description: 'Put life into hardware if MCU is contained. Complete firmware development.',
        fullDesc: <div>
          <ul>
            <li> Development of low level code in C or assembly </li>
            <li> Testing and tunning of code </li>
          </ul>
          <h5><span className='redFont'>Experience</span></h5>
          <ul>
            <li> Control of digital or analog inputs and outputs </li>
            <li> Servomotor or DC motor control </li>
            <li> PID regulation on MCU </li>
            <li> Communication I2C, SPI, UART, CAN </li>
            <li> Storage Flash, SD card, etc. </li>
            <li> And more </li>
          </ul>
        </div>
      },
      p3: {
        title: 'Higher system development, IoT',
        description: 'User interface development for data visualization or real-time control.',
        fullDesc: <div>
          <ul>
            <li> Application database </li>
            <li> Backend, C++, Nodejs, Python, REST api, websocket </li>
            <li> Frontend, React, javascript, HTML, CSS, Android - JAVA </li>
            <li> Integration of many various systems into one complex application </li>
          </ul>
          <h5><span className='redFont'>Experience</span></h5>
          <ul>
            <li> Database Mysql, Postgresql </li>
            <li> Communication HTTP/2 (REST, SOAP) or websockets </li>
            <li> Linux for servers hosting, Bash scripts for maintenance </li>
            <li> C++ server linux applications</li>
            <li> Nodejs REST api and interaction with databases</li>
            <li> Frontend applications for web broswers or mobile devices</li>
          </ul>
        </div>
      },
      p4: {
        title: '3D models and it\'s prototyping',
        description: '3D model development and manufacture prototype using 3D printer.',
        fullDesc: <div>
          <ul>
            <li> 3D models development </li>
            <li> 3D printing of prototypes </li>
            <li> Prototype assembly </li>
          </ul>
          <h5><span className='redFont'>Experience</span></h5>
          <ul>
            <li> Lasergame gun 3D model development </li>
            <li> Development of electronic covers </li>
            <li> Experience with machining, that is, we think about the feasibility of designing the model </li>
          </ul>
        </div>
      }
    },
    company: {
      header: 'Company',
      founders: 'Founders',
      description: <div>
        <p>
          <span className='redFont'>H</span>i-
          <span className='redFont'>T</span>ech
          <span className='redFont'> D</span>igital
          <span className='redFont'> M</span>odules was officially established in July 2020 however unofficially
          in 2015 when team of 4 members decided to create own lasergame equipment.
          During almost 3 years period of development, the project has reached a stage of functional prototypes and
          in spring 2019 was released into real environment. Company achieved a lot of experince and
          knowledge about electronic devices, PCB's, MCU programming, communications and
          user interface development. To reach aim's of pilot project was necessary to use agile method
          of development, planning, version control, automatic test's and continues integration of final application.
          Now the company uses modern technologies for project development. We want to use and improve mentioned experience therefore <span className='redFont'>we want to find new challenges, new projects.</span></p>
        <p>
          The absolute core of the company consists of 4 people, each focusing in a different direction, and everyone enjoys something else. Thanks to that,
          the communication between these people is great and the objectives of the projects can be achieved.
        </p>
      </div>,
      foundersDetails: <div>
        <h4><span className='redFont'>H</span>onza Sopuch</h4>
        <p>The owner of the entertainment centre <a target="_blank" rel="noopener noreferrer" href='https://www.zabavahornik.cz'>Horník</a> in Ostrava who is supporting a pilot lasergame project. </p>
        <h4><span className='redFont'>T</span>omáš Dočekal</h4>
        <p>MCU programming, HW development and 3D models.</p>
        <h4><span className='redFont'>D</span>aniel Lazar</h4>
        <p>Project management, higher level software, linux and software development. </p>
        <h4><span className='redFont'>M</span>atěj Golembiovský</h4>
        <p>Electronics and printed circuit board development.</p>
      </div>,
    },
    projectsDone: {
      header: 'Realized projects',
      introduction: <>
        <h3>Independent lasergame</h3>
        <p className='text-left'>Pilot project do by company HTDM. Project completely developed from scratch,
          own electronic, unique design, everything easy to be configured and organized in graphical environment on browser.
        </p>

        <ul className='text-left'>
          <li>ZigBee used</li>
          <li>Own electronic</li>
          <li>3D models for gun, chest and electronic plastic covers</li>
          <li>STM, ATmega MCUs and its programming </li>
          <li>Raspberry pi used as main unit for zigbee network coordination and application hosting </li>
          <li>User friendly frontend application for ease of use  </li>
        </ul>
      </>
    },
    roles: {
      jan: "marketing and sales",
      daniel: "software development",
      matej: "hardware development",
      tomas: "3D and firmware development",
    }
  }
};

export {
  language,
}