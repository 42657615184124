import React, { Component } from 'react';
import BannerCarusel from "./BannerCarusel";
import {language} from "../assets/languages";
import independent from '../assets/images/independentlogo.png';
import weaponmodel from "../assets/images/weaponmodel.png";
import chestmodel from "../assets/images/chestmodel.png";
import frontend from "../assets/images/frontend.png";
import mobileFrontend from "../assets/images/mobileFrontend.png";
import {withGetScreen} from 'react-getscreen'

class Projects extends Component {
  render() {
    const isMobile = this.props.isTablet() || this.props.isMobile();
    const lan = this.props.match.params.language;
    return <div className='homeHeader'>
      <BannerCarusel className='companyBanner'/>
      <div className='text-center container '>
        <h3 style={{marginTop: '80px'}}><span className='redFont'>{ language[lan].projectsDone.header }</span></h3>
        <div className='row projectIndependet'>
          <div className='col-lg-4 col-sm-12 logo'>
            <img alt="In logo" src={independent}/>
            <p>C++, C, Nodejs, React, 3D </p>
          </div>
          <div className='col-lg-8 col-sm-12 description'>
            {language[lan].projectsDone.introduction}
            <div className='row'>
              <div className='col-sm-6 text-center'>
                <img className='gunImage' alt="Weapon model" src={weaponmodel} />
              </div>
              <div className='col-sm-6 text-center'>
                <img alt="Chest model" src={chestmodel} />
              </div>
            </div>
            <div style={{marginTop: '20px'}} className='row'>
              <div className='col-sm-12 text-center'>
                <img className='gunImage' alt="frontend" src={ isMobile ? mobileFrontend : frontend } />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  }
}

export default withGetScreen(Projects);