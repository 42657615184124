import React, { Component } from 'react';
import logo from './logo.png';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/navbar.css';
import './assets/css/home.css';
import './assets/css/company.css';
import './assets/css/contact.css';
import './assets/css/projects.css';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import Navigation from './components/Navigation';
import NotificationSystem from 'react-notification-system';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Home from './components/Home';
import ScrollToTop from 'react-scroll-up';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faYoutube, faSlack } from '@fortawesome/free-brands-svg-icons'
import { faTimes, faCheck, faUsers, faTrophy, faBusinessTime, faFireExtinguisher, faHome, faCaretSquareUp } from '@fortawesome/free-solid-svg-icons';
import Contact from "./components/Contact";
import { Helmet } from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Company from "./components/Company";
import Specialization from "./components/Specialization";
import Projects from "./components/Projects";
import Contacts from "./components/Contacts";
library.add(faTimes);
library.add(faCheck);
library.add(faUsers);
library.add(faTrophy);
library.add(faBusinessTime);
library.add(faFireExtinguisher);
library.add(faHome);
library.add(faCaretSquareUp);
library.add(faFacebook, faTwitter, faSlack, faYoutube);

const NotificationSystemContext = React.createContext();

class App extends Component {

  constructor(props) {
    super(props);
    this._notificationSystem = null;
  }

  componentDidMount () {
    this._notificationSystem = this.refs.notificationSystem;
  }

  render() {
    let style = {
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          borderRadius: '5px',
          borderTop: 'none',
          isVisible: {
            opacity: 0.9,
          },
        },
      },
      Containers: {
        tr: {
          top: '70px',
          bottom: 'auto',
          left: 'auto',
          right: '0px',
        },
      },
    };

    return (
      <NotificationSystemContext.Provider value={{
        onAddNotification: (data) => this._notificationSystem.addNotification(data),
      }}>
        <Helmet>
          <title>Application development | HTDM - High Tech Digital Modules </title>
          <meta name="description" content="Vývoj aplikací na míru, Vyvineme Hardware, ožívíme pomocí Firmware a nad tím vytvoříme Software
          pro řízení a vizualizaci dat. Máme také četné zkušenosti s vývojem 3D modelů a s jeho tiskem" />
          <meta name="keywords" cpntent="HTDM, SW, HW, FW, 3D, Firmware, Software, Hardware Independent LaserTag LaserGame" />
          <meta name="google-site-verification" content="jf2fAcGpHn6uXXT4s912fZZ7VslVEipNpB3r4hyj2W0" />
        </Helmet>
        <Router >
          <div>
            <ScrollToTop style={{zIndex: 1000}} showUnder={800}>
              <span className='upButton'><FontAwesomeIcon icon="caret-square-up" /></span>
            </ScrollToTop>
            <NotificationSystem ref="notificationSystem" style={style}/>
            <Route exact path="/" render={() => (
              <Redirect to="/cz/home"/>
            )}/>
            <Route exact path="/:language" render={() => (
              <Redirect to="/cz/home"/>
            )}/>
            <Route path='/:language/:nav' component={Navigation}/>
            <Route exact path='/:language/home'component={Home}/>
            <Route exact path='/:language/company'component={Company}/>
            <Route exact path='/:language/specialization'component={Specialization}/>
            <Route exact path='/:language/projects'component={Projects}/>
            <Route exact path='/:language/contacts'component={Contacts}/>
            <Route path='/:language/' render={(props) => (
              <NotificationSystemContext.Consumer>
                {
                  value => (
                    <Contact {...props} contextValue={value}/>
                  )
                }
              </NotificationSystemContext.Consumer>

            )}/>
          </div>
        </Router>
      </NotificationSystemContext.Provider>

    );
  }
}

export const NotificationSystemContextConsumer = NotificationSystemContext.Consumer;
export default App;
