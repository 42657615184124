import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import hwimage from '../assets/images/hwbanner.jpg';
import fwimage from '../assets/images/fwbanner.jpg';
import uiimage from '../assets/images/uiimage.jpeg';
import _3dbanner from '../assets/images/3dbanner.jpeg';

class BannerCarusel extends Component {
  render() {
    const { className } = this.props;
    return <Carousel showArrows={false} showStatus={false} showThumbs={false} transitionTime={500} infiniteLoop autoPlay interval={10000}>
      <div>
        <img alt="banner" src={hwimage} className={className + ' hw'}/>
      </div>
      <div>
        <img alt="banner" src={fwimage} className={className + ' fw'}/>
      </div>
      <div>
        <img alt="banner" src={uiimage} className={className + ' ui'}/>
      </div>
      <div>
        <img alt="banner" src={_3dbanner} className={className + ' d3'}/>
      </div>
    </Carousel>
  }
}

BannerCarusel.propTypes = {
  className: PropTypes.string,
};

export default BannerCarusel;