import React, { Component } from 'react';
import BannerCarusel from "./BannerCarusel";
import {language} from "../assets/languages";
import hwprojectImage from '../assets/images/hwprojectImage.jpg';
import fwprojectimage from '../assets/images/fwprojectimage.jpg';
import uiprojectimage from '../assets/images/uiprojectimage.jpeg';
import _3dprojectimage from '../assets/images/3dprojectimage.jpeg';
import {withGetScreen} from 'react-getscreen';
import scrollToComponent from 'react-scroll-to-component';

class Specialization extends Component {

  componentDidMount() {

    if (typeof this.props.location.state === 'undefined')
      return;
    let to = null;
    switch (this.props.location.state.to) {
      case 'hw':
        to = this.hw;
        break;
      case 'fw':
        to = this.fw;
        break;
      case 'ui':
        to = this.ui;
        break;
      case '3d':
        to = this._3d;
        break;
    }
    scrollToComponent(to, { offset: -70, align: 'top', duration: 800});
  }

  render() {
    const lan = this.props.match.params.language;
    const isMobile = this.props.isTablet() || this.props.isMobile();

    const firDescr = <div className='col-md-6 item'>
      <h3 className='name'><span className='redFont'>{ language[lan].projects.p2.title }</span></h3>
      <p className="description">{ language[lan].projects.p2.description }</p>
      { language[lan].projects.p2.fullDesc }
    </div>;
    const _3Ddescr = <div className='col-md-6 item'>
      <h3 className='name'><span className='redFont'>{ language[lan].projects.p4.title }</span></h3>
      <p className="description">{ language[lan].projects.p4.description }</p>
      { language[lan].projects.p4.fullDesc }
    </div>;
    return <div className='homeHeader'>
      <BannerCarusel className='companyBanner'/>
      <div className='text-center container '>
        <h3><span className='redFont'>{ language[lan].homeSpecHeader }</span></h3>
        { language[lan].projects.main }
      </div>
      <div className='fixHowirizonalScroll container'>
        <div className='row projects'>
          <div className='divider'/>
          <section ref={(section) => { this.hw = section; }}/>
            <div className='col-md-6'>
              <div className="projectPicture">
                <img alt="hwImage" src={hwprojectImage}/>
              </div>
            </div>
            <div className='col-md-6 item'>
              <h3 className='name'><span className='redFont'>{ language[lan].projects.p1.title }</span></h3>
              <p className="description">{ language[lan].projects.p1.description }</p>
              { language[lan].projects.p1.fullDesc }
            </div>
          <div className='divider'/>
          <section ref={(section) => { this.fw = section; }}/>
            {isMobile ? null : firDescr}
            <div className='col-md-6'>
              <div className="projectPicture">
                <img alt="hwImage" src={fwprojectimage}/>
              </div>
            </div>
          {isMobile ? firDescr : null}
          <div className='divider'/>
          <section ref={(section) => { this.ui = section; }}/>
            <div className='col-md-6'>
              <div className="projectPicture">
                <img alt="hwImage" src={uiprojectimage}/>
              </div>
            </div>
            <div className='col-md-6 item'>
              <h3 className='name'><span className='redFont'>{ language[lan].projects.p3.title }</span></h3>
              <p className="description">{ language[lan].projects.p3.description }</p>
              { language[lan].projects.p3.fullDesc }
            </div>
          <div className='divider'/>
          <section ref={(section) => { this._3d = section; }}/>
            {isMobile ? null : _3Ddescr}
            <div className='col-md-6'>
              <div className="projectPicture">
                <img alt="hwImage" src={_3dprojectimage}/>
              </div>
            </div>
            {isMobile ? _3Ddescr : null}
          <div className='divider'/>
        </div>
      </div>
    </div>
  }
}

export default withGetScreen(Specialization);