import React, { Component } from 'react';
import BannerCarusel from "./BannerCarusel";
import {language} from "../assets/languages";

class Contacts extends Component {

  componentDidMount() {
    /*global google*/
    let parliament = new google.maps.LatLng(49.835140, 18.290030);
    let marker;
    let myOptions = {
      zoom: 12, center: parliament, mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

    marker = new google.maps.Marker({
      position: parliament, map: map, title: "Zámecká 1936/18, 702 00 Moravská Ostrava a Přívoz",
    });
  }

  render() {
    const lan = this.props.match.params.language;
    return <div className='homeHeader'>
      <BannerCarusel className='companyBanner'/>
      <div className='text-center container '>
        <h3><span className='redFont'>{ language[lan].contact.header2 }</span></h3>
        <div style={{marginTop: '20px',marginBottom: '50px'}} className='row contacts'>
          <div style={{marginTop: '30px'}} className='col-lg-3 col-sm-6'>
            <h5 style={{fontWeight: 'bold'}}>
              Jan Sopuch
            </h5>
            <div className='redFont' style={{fontSize: '15px', marginBottom: '7px'}}>
              {language[lan].roles.jan}
            </div>
            <div>
              Tel: +420 732 540 424
            </div>
            <div>
              Email: jan.sopuch@htdm.cz
            </div>
          </div>
          <div style={{marginTop: '30px'}} className='col-lg-3 col-sm-6'>
            <h5 style={{fontWeight: 'bold'}}>
              Tomáš Dočekal
            </h5>
            <div className='redFont' style={{fontSize: '15px', marginBottom: '7px'}}>
              {language[lan].roles.tomas}
            </div>
            <div>
              Tel: +420 721 014 636
            </div>
            <div>
              Email: tomas.docekal@htdm.cz
            </div>
          </div>
          <div style={{marginTop: '30px'}} className='col-lg-3 col-sm-6'>
            <h5 style={{fontWeight: 'bold'}}>
              Daniel Lazar
            </h5>
            <div className='redFont' style={{fontSize: '15px', marginBottom: '7px'}}>
              {language[lan].roles.daniel}
            </div>
            <div>
              Tel: +420 725 555 915
            </div>
            <div>
              Email: daniel.lazar@htdm.cz
            </div>
          </div>
          <div style={{marginTop: '30px'}} className='col-lg-3 col-sm-6'>
            <h5 style={{fontWeight: 'bold'}}>
              Matěj Golembiovský
            </h5>
            <div className='redFont' style={{fontSize: '15px', marginBottom: '7px'}}>
              {language[lan].roles.matej}
            </div>
            <div>
              Tel: +420 773 201 330
            </div>
            <div>
              Email: matej.golembiovsky@htdm.cz
            </div>
          </div>
        </div>
        <h5>
          IČ: 09368124
        </h5>
        <div>
          Zámecká 1936/18, 702 00 Moravská Ostrava a Přívoz
        </div>
        <div id="map_canvas" style={{width:'100%', height:'500px'}}></div>
      </div>

    </div>
  }
}

export default Contacts;