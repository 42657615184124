import React, { Component } from 'react';
import BannerCarusel from "./BannerCarusel";
import {language} from "../assets/languages";

class Company extends Component {
  render() {
    const lan = this.props.match.params.language;
    return <div className='homeHeader'>
      <BannerCarusel className='companyBanner'/>
      <div className='text-center container '>
        <h3><span className='redFont'>{ language[lan].company.header }</span></h3>
      </div>
      <div className='container'>
        { language[lan].company.description }
      </div>
      <div className='text-center container '>
        <h3><span className='redFont'>{ language[lan].company.founders }</span></h3>
      </div>
      <div className='container'>
        { language[lan].company.foundersDetails }
      </div>
    </div>
  }
}

export default Company;