import React, { Component } from 'react';
import {language} from "../assets/languages";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hwprojectImage from '../assets/images/hwprojectImage.jpg';
import fwprojectimage from '../assets/images/fwprojectimage.jpg';
import uiprojectimage from '../assets/images/uiprojectimage.jpeg';
import _3dprojectimage from '../assets/images/3dprojectimage.jpeg';
import BannerCarusel from './BannerCarusel';

class Home extends Component {
  render() {
    const lan = this.props.match.params.language;
    return <div className='text-center homeHeader'>
      <BannerCarusel className='homeBanner'/>

      <h3><span className='redFont'>{ language[lan].homeHeaderH3 }</span></h3>
      <h6>
        { language[lan].homeHeaderH6 }
      </h6>
      <div className='container margin40 text-left'>
        {language[lan].homeIntro}
      </div>

      <div className='fixHowirizonalScroll container'>
        <div className='row features-boxed margin40'>
            <div className='row skillBox'>
              <div className='col-sm-4 col-lg-2 offset-lg-1 item'>
                <div className="box">
                  <div className="icon">
                    <FontAwesomeIcon icon="users" />
                  </div>
                  {language[lan].skillBox.employees}
                </div>
              </div>
              <div className='col-sm-4 col-lg-2 item'>
                <div className="box">
                  <div className="icon">
                    <FontAwesomeIcon icon="trophy" />
                  </div>
                  {language[lan].skillBox.experience}
                </div>
              </div>
              <div className='col-sm-4 col-lg-2 item'>
                <div className="box">
                  <div className="icon">
                    <FontAwesomeIcon icon="business-time" />
                  </div>
                  {language[lan].skillBox.agile}
                </div>
              </div>
              <div className='col-sm-6 col-lg-2 item'>
                <div className="box">
                  <div className="icon">
                    <FontAwesomeIcon icon="fire-extinguisher" />
                  </div>
                  {language[lan].skillBox.hotTeam}
                </div>
              </div>
              <div className='col-sm-6 col-lg-2 item'>
                <div className="box">
                  <div className="icon">
                    <FontAwesomeIcon icon="home" />
                  </div>
                  {language[lan].skillBox.laserGame}
                </div>
              </div>
            </div>
        </div>
      </div>

      <h3><span className='redFont'>{ language[lan].homeSpecHeader }</span></h3>

      <div className='fixHowirizonalScroll container'>
        <div className='row projects-boxed'>
          <div className='col-sm-6 item' onClick={() => {
            this.props.history.push({
              pathname: `/${lan}/specialization`,
              state: {to: 'hw'},
            })
          }}>
            <div className="box">
              <div className="icon">
                <img alt="hwImage" src={hwprojectImage}/>
              </div>
              <div className='label-inside-project'>
                <h3 className='name'>{ language[lan].projects.p1.title }</h3>
                <p className="description">{ language[lan].projects.p1.description }</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 item' onClick={() => {
            this.props.history.push({
              pathname: `/${lan}/specialization`,
              state: {to: 'fw'},
            })
          }}>
            <div className="box">
              <div className="icon">
                <img alt="hwImage" src={fwprojectimage}/>
              </div>
              <div className='label-inside-project'>
                <h3 className='name'>{ language[lan].projects.p2.title }</h3>
                <p className="description">{ language[lan].projects.p2.description }</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 item' onClick={() => {
            this.props.history.push({
              pathname: `/${lan}/specialization`,
              state: {to: 'ui'},
            })
          }}>
            <div className="box">
              <div className="icon">
                <img alt="hwImage" src={uiprojectimage}/>
              </div>
              <div className='label-inside-project'>
                <h3 className='name'>{ language[lan].projects.p3.title }</h3>
                <p className="description">{ language[lan].projects.p3.description }</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 item' onClick={() => {
            this.props.history.push({
              pathname: `/${lan}/specialization`,
              state: {to: '3d'},
            })
          }}>
            <div className="box">
              <div className="icon">
                <img alt="hwImage" src={_3dprojectimage}/>
              </div>
              <div className='label-inside-project'>
                <h3 className='name'>{ language[lan].projects.p4.title }</h3>
                <p className="description">{ language[lan].projects.p4.description }</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Home;