import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class CollapseButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    }
  }
  render() {
    let { collapsed } = this.state;
    return <button className="navbar-toggler"
                   onClick={() => this.setState({
                     collapsed: !collapsed,
                   })}
                   type="button"
                   data-toggle="collapse"
                   data-target={this.props.dataTarget}>
      { collapsed ? null : <span style={{marginRight: '15px'}}>Menu</span> }
      { collapsed ? <FontAwesomeIcon style={{color: '#5E5E5E', fontSize: '22px'}} icon="times" /> : <span className="navbar-toggler-icon"/>}
      </button>
  }
}

CollapseButton.propTypes = {
  dataTarget: PropTypes.string.isRequired,
};

export default CollapseButton;